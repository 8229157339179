import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import Button from "../components/Button/Button"
import { BlogSingleStyles } from "../components/Info/InfosStyles"


const about = () => {
  return (
    <>
      <Seo title="privacy" />
      <Layout>
         <BlogSingleStyles>
            <h1 className="blogsingle__title">Поверителност на данни</h1>           
            <article className="blogsingle__content">
              <p>Чл. 1 (1) <strong>“Вензел” ЕООД</strong> има право да събира и използва информация относно Клиентите след извършената от тях регистрация/поръчка в нейната страница <strong>www.venzel.bg</strong>. Информацията, чрез която лицето може да бъде идентифицирано, може да включва име, фамилия, адрес, телефон, както и всяка друга информация, която лицето предоставя доброволно при регистрацията, осъществяване на поръчка, изпращане на съобщение или при общуване със служител. Информацията включва и всяка друга, която Клиентът въвежда, използва или предоставя при ползване услугите, предоставяни от “Вензел” ЕООД.</p>
              <p>(2) Всяка предоставена информация относно личните данни, ще се съхранява от <strong>“Вензел” ЕООД</strong>.</p>
              <p>Чл. 2 (1) <strong>“Вензел” ЕООД</strong> полага дължимата грижа и отговаря за защита на информацията за Клиента, станала му известна по повод на регистрацията/поръчката – предмет на Общите условия, освен в случаите на непреодолима сила, случайно събитие или злоумишлени действия на трети лица.</p>
              <p>(2) В регистрационната форма, попълвана от клиента при регистрацията/поръчката, <strong>“Вензел” ЕООД</strong> обозначава задължителния или доброволния характер на предоставяне на данните, и последиците от отказа за предоставянето им. С изразяване на съгласие с Общите условия Клиентът се съгласява информацията за него да бъде обработвана по предвидения в тях ред.</p>
              <p>(3) С регистрацията и приемането на настоящите Общи условия Клиентът се съгласява с обработването на личните му данни единствено за управление на поръчки, маркетингови промоционални цели, изпращане на информационен бюлетин, и за връзка с потребителите в случай на възникнали проблеми или уточняване на детайли, свързани с поръчката и нейната доставка.</p>
              <p>(4) Ограниченията по ал. 1 не се прилагат в случай, че за <strong>“Вензел” ЕООД</strong> възникне задължение да предостави личната информация за Потребителя на съответните компетентни държавни органи съгласно действащото законодателство.</p>
              <p>Чл. 3 (1) Потребителят може да се регистрира като попълни съответната електронна форма за регистрация, достъпна в реално време (on-line) в Интернет на <strong>www.venzel.bg</strong>, да изрази съгласие с Общите условия и да декларира, че е правоспособен.</p>
              <p>(2) Чрез натискане на виртуалния бутон “Регистрация”, имащо силата на писмено потвърждение на Общите условия, Клиентът извършва електронно изявление по смисъла на Закона за електронния документ и електронния подпис, с което декларира, че е запознат с настоящите Общи условия, приема ги и се задължава да ги спазва. Със записването му на съответен носител в сървъра на <strong>“Вензел” ЕООД</strong>, чрез общоприет стандарт за преобразуване по технически начин, правещ възможно неговото възпроизвеждане, електронното изявление придобива качеството на електронен документ по смисъла на цитирания закон, <strong>“Вензел” ЕООД</strong> може да съхранява на сървър IP адреса на Клиента, както и всяка друга информация, необходима за идентифицирането му и възпроизвеждане на електронното му изявление за приемане на Общите условия в случай на възникване на правен спор. Текстът на настоящите Общи условия е достъпен в Интернет на <strong>www.venzel.bg</strong> по начин, който позволява неговото съхраняване и възпроизвеждане.</p>
              <p>(3) При попълване на заявлението за регистрация Клиентът е длъжен да предостави пълни и верни данни относно самоличността (за физически лица), правния статут (за юридически лица) и другите изискуеми от електронната форма на <strong>www.venzel.bg</strong> данни както и да ги актуализира в 7 (седем) дневен срок от тяхната промяна. Потребителят декларира, че е съгласен да предостави така изискваните лични данни, при което гарантира, че данните, които предоставя в процеса на регистрация, са верни, пълни и точни и при промяна на последните ще ги актуализира своевременно. В случай на предоставяне на неверни данни <strong>“Вензел” ЕООД</strong> има право да прекрати или спре незабавно и без предизвестие предоставянето на услугите, както и поддържането на регистрацията на Клиента.</p>
              <p>Чл. 4 (1) При регистрацията си Клиентът получава уникално потребителско име и парола за достъп до услугите, достъпни чрез www.venzel.bg.</p>
              <p>(2) Потребителското име, с което Клиентът се регистрира, не му дава никакви други права, освен правото да ползва конкретното потребителско име в рамките на www.venzel.bg</p>
              <p>(3) Регистриращият се в качеството си на представител на юридическо лице е длъжен да въведе пълното си име и адрес, респективно наименованието на юридическото лице, което представлява. С приемането на Общите условия той гарантира и потвърждава, че има договорни отношения с регистрираното от него дружество/потребител. При въвеждането на некоректна или подвеждаща информация страната се лишава от правото на достъп до услугите на www.venzel.bg.</p>
              <p>(4) Клиента е длъжен да полага всички грижи и да предприема необходимите мерки, с цел опазване на паролата си, както и да не прави достояние на трети лица паролата си и да уведомява незабавно <strong>“Вензел” ЕООД</strong> в случай на осъществен неправомерен достъп, както и при вероятност и съмнение за такъв. Клиентът носи отговорността за опазването на своята парола, както и за всички действия, които се извършват от него или от трето лице чрез използване на паролата му.</p>
              <p>Чл. 5 (1) Клиентът има право на достъп в режим on-line до услугите, предоставяни чрез www.venzel.bg, при спазване на условията и изискванията за достъп, определен от <strong>“Вензел” ЕООД</strong>.</p>
              <p>(2) Клиентът има право на достъп и на корекция в режим on-line на личните си данни, предоставени при регистрацията/поръчка.</p>
              <p>(3) Клиентът има право да поиска от администратора да изтрие личните му данни, като за целта изпрати писмено искане – лично или на venzelworkbg@gmail.com. Администраторът изтрива личните данните на клиента в рамките на три работни дни.</p>
            
              <div className="blogsingle__back">
              <Button text="Към начална страница" as={Link} to="/" />
              </div>
            </article>
          </BlogSingleStyles>
      </Layout>
    </>
  )
}

export default about
